import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useGetChatValueCodesQuery } from "../../../store/reducers/adminApi";
import Badge from "react-bootstrap/Badge";

export function ChatValueCodes() {
  const { data: chatValueCodes, refetch } = useGetChatValueCodesQuery(null);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Code</th>
            <th>Value</th>
            <th>Used By</th>
            <th>Created On</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {chatValueCodes &&
            chatValueCodes.map((chatValueCode) => {
              return (
                <tr key={chatValueCode.id}>
                  <td>{chatValueCode?.code}</td>
                  <td>${(chatValueCode.value / 100).toFixed(2)}</td>
                  <td>
                    {chatValueCode?.usedBy ? (
                      chatValueCode.usedBy.email
                    ) : (
                      <Badge bg="danger">Not Used</Badge>
                    )}
                  </td>
                  <td>{moment(chatValueCode.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                  <td></td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}
