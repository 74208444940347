import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router";
import { useGetUserQuery, useUpdateUserMutation } from "../../../store/reducers/adminApi";
import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { Link } from "react-router-dom";
import ToastBar, { ToastData } from "../../../components/ToastBar/toastbar";
import { Button, Form, InputGroup } from "react-bootstrap";

export function User() {
  const navigate = useNavigate();
  let { userID } = useParams();
  const [chatValue, setChatValue] = useState(0)
  const {
    data: user,
    refetch,
  } = useGetUserQuery(userID, { refetchOnFocus: true, refetchOnMountOrArgChange: true });

  const [updateUser] = useUpdateUserMutation();

  const [toastMessage, setToastMessage] = useState<ToastData>({
    title: null,
    body: null,
    type: null
  })

  const showToast = (title: string, body: string, type: 'success' | 'danger' | null) => {
    setToastMessage({
      title,
      body,
      type
    })
  }

  const toggleEnable = () => {
    updateUser({ userID: user.userID, enabled: !user.enabled }).unwrap().then(() => {
      refetch()
      showToast('Action', `User ${user.enabled ? 'disabled' : 'enabled'} successfully`, 'success')
    }).catch((e) => {
      showToast('Error', e?.message || 'Something went wrong!', 'danger')
    });
  };

  const updateChatRate = () => {
    updateUser({ userID: user.userID, chat_value_available: (chatValue * 100).toString() }).unwrap().then(() => {
      refetch()
      showToast('Action', `Chat value updated successfully!`, 'success')
    }).catch((e) => {
      showToast('Error', e?.message || 'Something went wrong!', 'danger')
    });
  };


  useEffect(()=>{
    const value = parseFloat(user?.chat_value_available || "0");
    setChatValue( value ? value / 100 : value )
  }, [user]);

  console.log('User', user);


  let totalSpend = 0;
  if (user?.payments) {
    user.payments.forEach((payment) => {
      totalSpend += payment.charge_amount;
    });
  }

  return (
      <div>
        {user && (
            <React.Fragment>
              <Image roundedCircle src={user.avatar} width={150} height={150} />
              <Table>
                <tbody>
                <tr>
                  <td>Status</td>
                  <td>
                    <Badge bg={user.enabled ? "success" : "danger"}>
                      {user.enabled ? "enabled" : "disabled"}
                    </Badge>
                  </td>
                </tr>
                <tr>
                  <td>Total Spend</td>
                  <td>
                      ${(totalSpend / 100).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <td>Username</td>
                  <td>{user.username}</td>
                </tr>
                <tr>
                  <td>Created On</td>
                  <td>{moment(user.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                </tr>
                <tr>
                  <td>Action</td>
                  <td>
                    <Button size="sm" variant={user.enabled ? "danger" : "success"} onClick={toggleEnable}>
                      {user.enabled ? "Disable" : "Enable"} User
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>Chat Credit</td>
                  <td>
                    <InputGroup className="w-50">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                          placeholder="Chat credit"
                          type="number"
                          min="0"
                          value={chatValue}
                          onChange={(e)=> setChatValue(parseFloat(e.target.value))}
                      />
                      <Button variant="outline-primary" disabled={chatValue !== 0 && !Number(chatValue)} onClick={updateChatRate}>
                        Update
                      </Button>
                    </InputGroup>
                  </td>
                </tr>
                </tbody>
              </Table>
              <h5>Payments</h5>
              <Table>
                <thead>
                <tr>
                  <th>Date</th>
                  <th>Charge Amount</th>
                  <th>Session Value</th>
                  <th>Chat Rate</th>
                  <th>Chat</th>
                </tr>
                </thead>
                <tbody>
                {user.payments.map((payment) => {
                  return (
                      <tr key={payment.paymentID}>
                        <td>{moment(payment.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                        <td>${(payment.charge_amount / 100).toFixed(2)}</td>
                        <td>${(payment.value / 100).toFixed(2)}</td>
                        <td>${(payment.chat_rate / 100).toFixed(2)}/min</td>
                        <td>
                          <Link to={`/chat/${payment.chatSession?.chatID}`}>
                            {payment.chatSession?.chatID}
                          </Link>
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </Table>


              {user.chatRequestsReceived && Array.isArray(user.chatRequestsReceived) && user.chatRequestsReceived.length > 0 ? <><h5>Chat Requests Received</h5>
                <Table>
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Psychic</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  {user.chatRequestsReceived.map((chatRequest) => {
                    return (
                        <tr key={chatRequest.chatRequestID}>
                          <td>{moment(chatRequest.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                          <td>{chatRequest.to_user?.name}</td>
                          <td>{chatRequest.status}</td>
                        </tr>
                    );
                  })}
                  </tbody>
                </Table></> : null}

              {user.chatRequestsSent && Array.isArray(user.chatRequestsSent) && user.chatRequestsSent.length > 0 ? <><h5>Chat Requests Sent</h5>
                <Table>
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Psychic</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  {user.chatRequestsSent.map((chatRequest) => {
                    return (
                        <tr key={chatRequest.chatRequestID}>
                          <td>{moment(chatRequest.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                          <td>
                            <a href={`/psychic/${chatRequest.to_user_id}`}>
                              {chatRequest.to_user_id}
                            </a>
                          </td>
                          <td>{chatRequest.status}</td>
                        </tr>
                    );
                  })}
                  </tbody>
                </Table></> : null}

              {user.chats && Array.isArray(user.chats) && user.chats.length > 0 ? <><h5>Chats</h5>
                <Table>
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  {user.chats.map((chat) => {
                    return (
                        <tr key={chat?.chatID}>
                          <td>
                            <a href={`/chat/${chat?.chatID}`}>
                              {moment(chat.created_at).format("MM-DD-YYYY HH:MM:SS")}
                            </a>
                          </td>
                          <td>{chat.status}</td>
                        </tr>
                    );
                  })}
                  </tbody>
                </Table></> : null}
            </React.Fragment>
        )}
        <ToastBar tostData={toastMessage} setToastData={setToastMessage} />
      </div>
  );
}
