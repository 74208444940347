import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuth } from "firebase/auth";
import { permissions } from "../../config/permissions";
import { transformServerMessage } from "./utils";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/",
    prepareHeaders: async (headers, { getState }) => {
      const auth = getAuth();

      const token = auth.currentUser ? await auth.currentUser.getIdToken(true) : null;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
    fetchFn: async (input) => {
      const controller = new AbortController();
      const id = setTimeout(() => {
        controller.abort();
      }, 5000);
      const res = await fetch(input);
      clearTimeout(id);
      return Promise.resolve(res);
    },
  }),
  tagTypes: ["Self", "User", "Review", "Chat", "ChatRequest", "ClosedChatSession"],
  endpoints: (builder) => ({
    getSelf: builder.query<User, null>({
      query: () => ({ url: "self" }),
      transformResponse: (user: User) => {
        user.permissions = permissions[user.user_role];
        return user;
      },
      providesTags: ["Self"],
    }),
    updateSelf: builder.mutation<User, Partial<User> & Pick<User, "userID">>({
      query: ({ userID, ...patch }) => ({
        url: `user/${userID}`,
        method: "PUT",
        body: patch,
      }),
    }),
    createUser: builder.mutation<User, Partial<User>>({
      query: ({ ...patch }) => ({
        url: `user`,
        method: "POST",
        body: patch,
        headers: {
          Authorization: `Bearer ${patch.token}`,
        },
      }),
      invalidatesTags: ["Self"],
    }),
    getChats: builder.query<Chat[], null>({
      query: () => ({ url: "chat" }),
      providesTags: ["Chat"],
    }),
    getChat: builder.query<Chat, string>({
      query: (chatID) => ({ url: `chat/${chatID}` }),
      providesTags: (result, error, arg) => [{ type: "Chat", id: arg }],
      transformResponse(chat: Chat) {
        chat.messages = transformServerMessage(chat.messages, chat.users) as ChatMessage[];
        chat.messages = chat.messages.reverse();
        return chat;
      },
    }),
    getChatRequests: builder.query<ChatRequest[], null>({
      query: () => ({ url: "chat-request" }),
      providesTags: ["ChatRequest"],
    }),
    getUsers: builder.query<User[], null>({
      query: () => ({ url: "user" }),
      providesTags: ["User"],
    }),
    getPaymentHistory: builder.query<ChatSession[], null>({
      query: () => ({ url: "payment/payment-history" }),
      providesTags: ["ClosedChatSession"],
    }),
    uploadProfileImage: builder.mutation<User, any>({
      query: (data) => ({
        url: "self/profile-picture",
        body: data,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetSelfQuery,
  useUpdateSelfMutation,
  useCreateUserMutation,
  useGetChatsQuery,
  useGetChatQuery,
  useGetChatRequestsQuery,
  useGetUsersQuery,
  useGetPaymentHistoryQuery,
  useUploadProfileImageMutation,
} = api;
