import { useEffect } from 'react';
import ToastContainer from 'react-bootstrap/esm/ToastContainer'
import Toast from 'react-bootstrap/Toast';

export type ToastData = {
  title: string | null,
  body: string | null,
  type: 'success' | 'danger' | null
};

interface Props {
  tostData?: ToastData;
  setToastData: (tostData?: ToastData) => void
}

function ToastBar(props: Props) {
  const { tostData, setToastData } = props
  const { title, body, type } = tostData;

  const handleClose = () => {
    if (title != null)
      setToastData({ title: null, body: null, type: null })
  }


  return (
    <ToastContainer className="p-3" position='top-end'>
      <Toast onClose={handleClose} animation={true} bg={type} show={title !== null} delay={3000} autohide>
        <Toast.Header closeButton={true}>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body className="text-white">{body}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default ToastBar