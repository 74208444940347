import React, {useState} from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { useGetChatsQuery } from "../../../store/reducers/adminApi";
import { useNavigate } from "react-router";

export function Chats() {
    const [page, setPage] = useState(1)
    const { isLoading, isFetching, isSuccess, isError, error, data: chats, refetch } = useGetChatsQuery(page);

    const navigate = useNavigate();

    return (
        <div>
            Page # {page}
            <Table striped bordered hover className="table-hover">
                <thead>
                <tr>
                    <th>Psychic</th>
                    <th>User</th>
                    <th>Created At</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {chats &&
                    chats.map((chat) => {
                        const active = chat.chatSessions.find((chatSession) => chatSession.status === "OPEN");
                        const psychic = chat.users.find((user) => user.user_role === "psychic" || user.user_role !== "user");
                        const user = chat.users.find((user) => user.user_role === "user" || user.user_role !== "psychic");

                        return (
                            <tr key={chat.chatID} onClick={() => navigate(`/chat/${chat.chatID}`)}>
                                <td>{psychic.name}</td>
                                <td>{user.name}</td>
                                <td>{moment(chat.created_at).format("MM/DD/YYYY")}</td>
                                <td>
                                    <Badge bg={active ? "success" : "danger"}>{active ? "active" : "closed"}</Badge>
                                </td>
                            </tr>
                        );
                    })}
                Current Page: {page}
                {page > 1 ? <button style={{marginRight: '3px', marginLeft: '3px'}} onClick={() => setPage(page - 1)}>
                    Previous
                </button> : <></>}

                {chats != null && chats.length > 0 ? <button style={{marginRight: '3px', marginLeft: '3px'}} onClick={() => setPage(page + 1)}>
                    Next
                </button> : <></>}
                </tbody>
            </Table>
        </div>
    );
}
