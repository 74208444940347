import React, {useState} from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Button from "react-bootstrap/Button";
import {
    adminApi,
    useGetTransfersQuery,
    useUpdateTransfersMutation,
} from "../../../store/reducers/adminApi";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {useAppDispatch} from "../../../hooks/useAppSelector";
import "./transfers.css";

export function Transfers() {
    const dispatch = useAppDispatch();
    const {data: transfers, refetch} = useGetTransfersQuery(null);
    const [updateTransfers, updateTransfersResult] = useUpdateTransfersMutation();
    const [loading, setLoading] = useState(false);
    const [modTransfers, setModTransfers] = useState([]);

    const changeTransferStatus = (transfer, e) => {
        const t = modTransfers.find((t) => transfer.transferID === t.transferID);

        transfer = t ? t : structuredClone(transfer);
        transfer.status = e.target.value;
        if (!t) modTransfers.push(transfer);

        setModTransfers(modTransfers);

        dispatch(
            adminApi.util.updateQueryData("getTransfers", null, (draftTransfers) => {
                let draftTransfer = draftTransfers.find((t) => t.transferID === transfer.transferID);
                if (draftTransfer) draftTransfer.status = e.target.value;
            })
        );

        console.log(modTransfers);
    };

    const updateStatus = () => {
        setLoading(true);
        updateTransfers(modTransfers)
            .unwrap()
            .finally(() => {
                refetch();
                setLoading(false);
            });
    };

    return (
        <div>
            <div className="d-flex flex-row-reverse">
                <Button variant="success" onClick={() => updateStatus()} disabled={loading}>
                    Save All
                </Button>
            </div>
            <Table striped bordered>
                <thead>
                <tr>
                    <th>Created On</th>
                    <th>Amount</th>
                    <th>To User</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {transfers &&
                    transfers.map((transfer) => {
                        return (
                            <tr key={transfer.transferID}>
                                <td>{moment(transfer.created_at).format("MM-DD-YYYY")}</td>
                                <td>
                                    {(transfer.amount / 100).toFixed(2)}
                                    {transfer.status !== "SUCCEEDED" && <div>
                                        <span>Charged: {transfer.source_charge_amount == null ? "N/A" : (transfer.source_charge_amount / 100).toFixed(2) ?? "N/A"}; Balance: {transfer.stripe_balance == null ? "N/A" :(transfer.stripe_balance / 100).toFixed(2) ?? "N/A"}</span>
                                    </div>}
                                </td>
                                <td>
                                    {transfer.user && (
                                        <Link to={`/psychic/${transfer.user.userID}`}>{transfer.user.username}</Link>
                                    )}
                                </td>
                                <td className="d-flex flex-row">
                                    <Form.Select
                                        onChange={(val) => changeTransferStatus(transfer, val)}
                                        value={transfer.status}
                                        className={transfer.status !== "SUCCEEDED" ? "option-failed" : ""}
                                    >
                                        <option value="SUCCEEDED">Succeeded</option>
                                        <option value="FAILED">Failed</option>
                                        <option value="NOT SENT">Not Sent</option>
                                    </Form.Select>

                                    {transfer.status !== "SUCCEEDED" && <div>
                                        <span className="text-danger">Error: {transfer.error_text}</span>
                                    </div>}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}
