import { configureStore } from "@reduxjs/toolkit";
import { adminApi } from "./reducers/adminApi";
import { api } from "./reducers/api";
import authReducer from "./reducers/auth";
import chatMessageSlice from "./reducers/chatMessageSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    api: api.reducer,
    adminApi: adminApi.reducer,
    chatMessage: chatMessageSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat(adminApi.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
