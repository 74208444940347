import React, {useEffect, useRef, useState} from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import {useGetChatRequestAnalyticsQuery, useGetChatsQuery} from "../../../store/reducers/adminApi";
import { useNavigate } from "react-router";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
  CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement,
  Title, Tooltip
} from 'chart.js';
import type { ChartData, ChartOptions } from 'chart.js';

import { Line } from 'react-chartjs-2';

export function Analytics() {
  // const cvRef = useRef<HTMLCanvasElement>(null);
  // const chartRef = useRef<Chart>(null);
  //
  // useEffect(() => {
  //   const chart = chartRef.current;
  // }, []);
  //
  // useEffect(() => {
  //   if (cvRef != null && cvRef.current != null) {
  //     const c = new Chart(cvRef.current, {
  //
  //     })
  //   }
  //
  //
  // }, [cvRef]);

  ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
  );
  const { data  } = useGetChatRequestAnalyticsQuery();
  const [showUsers, setShowUsers] = useState({});
  // get analytics object and sort by date:

  let analytics = data?.analytics?.slice() ?? [];
  // sort analytics by date:
  analytics.sort((a, b) => {
    return moment(b.date).valueOf() - moment(a.date).valueOf();
  });


  let userStats = data?.userStats?.slice() ?? [];
  userStats.sort((a, b) => {
    return moment(b.date).valueOf() - moment(a.date).valueOf();
  });

  let graphData = userStats.length > 0 ? {
    labels: userStats.map((stat) => moment(stat.date).format('MM/DD/YYYY')),
    datasets: [
      {
        label: 'New Users',
        data: userStats.map((stat) => stat.newUsers.length),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',

      },
      {
        label: 'Returning Users',
        data: userStats.map((stat) => stat.returningUsers.length),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',

      }
    ]
  } : { labels: [], datasets: []};
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // height: 50,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'New vs Returning users',
      },
    },
  };

  const calcPercentFor = (num: number, total: number) => {
    try {
      return (num/total*100).toFixed(0) + '%';
    } catch (e) {
      return '0%';
    }
  }

  return (
      <div>
        <Tabs defaultActiveKey="requests" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="requests" title="Chat Requests">
            <Table striped bordered hover className="table-hover">
              <thead>
              <tr>
                <th>Date</th>
                <th>Total Reqs</th>
                <th>Accepted</th>
                <th>Cancelled by user</th>
                <th>Cancelled by psychic</th>
                <th>Chat succ</th>
                <th>Payment Errors</th>
                <th>Err: Chat Not Found</th>
                <th>Err: User not found</th>
                <th>Err: Request expired</th>
                <th>Err: Validation err</th>
                <th>Err: Other</th>
                <th>Err: Request expired (on cancel)</th>
              </tr>
              </thead>
              <tbody>
              {analytics &&
                  analytics.map((row) => {
                    return (
                        <tr key={row.date.toString()}>
                          <td>{moment(row.date).format("MMM DD")}</td>
                          <td>{row.chats_requested}</td>
                          <td>{row.chats_accepted}</td>
                          <td>{row.chats_cancelled_by_user}</td>
                          <td>{row.chats_cancelled_by_psychic}</td>
                          <td>{row.chats_ended_successfully}</td>
                          <td>{row.err_on_accept_stripe_payment_failure} ({calcPercentFor(row.err_on_accept_stripe_payment_failure, row.chats_requested)})</td>
                          <td>{row.err_on_accept_chat_request_not_found} ({calcPercentFor(row.err_on_accept_chat_request_not_found, row.chats_requested)})</td>
                          <td>{row.err_on_accept_accept_request_as_requester} ({calcPercentFor(row.err_on_accept_accept_request_as_requester, row.chats_requested)})</td>
                          <td>{row.err_on_accept_chat_request_not_pending} ({calcPercentFor(row.err_on_accept_chat_request_not_pending, row.chats_requested)})</td>
                          <td>{row.err_on_accept_validation_error} ({calcPercentFor(row.err_on_accept_validation_error, row.chats_requested)})</td>
                          <td>{row.err_on_accept_other} ({calcPercentFor(row.err_on_accept_other, row.chats_requested)})</td>
                          <td>{row.err_on_cancel_chat_request_not_pending} ({calcPercentFor(row.err_on_cancel_chat_request_not_pending, row.chats_requested)})</td>
                        </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="users" title="New vs Returning Users">
            {/*{userStats.length > 0 ? <div style={{height: '300px', width: '100%', marginBottom: '15px'}}>*/}
            {/*  <Line  options={options} data={graphData} />*/}
            {/*</div>: null}*/}

            <Table striped bordered hover className="table-hover">
              <thead>
              <tr>
                <th>Date</th>
                <th>Requests from New users</th>
                <th>Requests from Returning users</th>
              </tr>
              </thead>
              <tbody>
              {userStats &&
                  userStats.map((row) => {
                    return (
                        <tr key={row.date.toString()}>
                          <td>{moment(row.date).format("MMM DD")}</td>
                          <td style={{color: row?.newUsers?.length == 0 ? "#c4c4c4" : ""}}>{row?.newUsers?.length}</td>
                          <td style={{color: row?.returningUsers?.length == 0 ? "#c4c4c4" : ""} } onClick={() => setShowUsers({...showUsers, [row.date]: !showUsers[row.date]})}>
                            {row?.returningUsers?.length} {showUsers[row.date] != true && row?.returningUsers?.length > 0 ? <span style={{fontSize: "0.8rem"}}>(click to show)</span> : null}
                            {showUsers[row.date] ? row?.returningUsers.map((user) => {return (<><br/><span key={user.toString()} style={{fontSize: "0.8rem", color: "#a4a4a4"}}>{user.username}</span></>);}) : null}
                          </td>
                        </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      </div>
  );
}
