import React, { useContext, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "bootstrap/dist/css/bootstrap.css";
import { firebaseConfig } from "./config/firebaseConfig";
import { userSignedIn, userSignedOut } from "./store/reducers/auth";
import { useAppDispatch, useAppSelector } from "./hooks/useAppSelector";
import { LoginPage } from "./pages/login/login";
import { Dashboard } from "./pages/dashboard/dashboard";
import { SocketContext } from "./context/socketContext";
import { constants } from "./config/constants";
import { api } from "./store/reducers/api";

firebase.initializeApp(firebaseConfig);

function App() {
  const dispatch = useAppDispatch();
  const signedIn = useAppSelector((state) => state.auth.signedIn);
  const socketClient = useContext(SocketContext);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        dispatch(userSignedOut());
        dispatch(api.util.invalidateTags(["Self"]));
      } else {
        dispatch(userSignedIn());
        dispatch(api.util.invalidateTags(["Self"]));
        socketClient.connect(
          constants.SOCKET_URL,
          user,
          () => {},
          () => {}
        );
      }
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [dispatch]);

  useEffect(() => {
    document.title = "Relationship Psychic Admin";
  }, []);

  if (!signedIn) {
    return <LoginPage />;
  } else {
    return <Dashboard />;
  }
}

export default App;
