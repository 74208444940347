export abstract class constants {
  static readonly ChatEvents = {
    message: "MESSAGE",
    disconnect: "DISCONNECT",
    connection: "CONNECTION",
    userDisconnected: "USER DISCONNECTED",
    loadChat: "LOAD CHAT",
    requestChat: "REQUEST CHAT",
    chatRequested: "CHAT REQUESTED",
    acceptChat: "ACCEPT CHAT",
    joinChat: "JOIN CHAT",
    endChat: "END CHAT",
    sessionDetails: "SESSION DETAILS",
    chatRequestAccepted: "CHAT REQUEST ACCEPTED",
    userJoinedChat: "USER JOINED CHAT",
    leftChatRoom: "LEFT CHAT ROOM",
    error: "ERROR",
    setUserTyping: "SET USER TYPING",
    userTyping: "USER TYPING",
    cancelRequest: "CANCEL CHAT REQUEST",
    chatRequestCanceled: "CHAT REQUEST CANCELED",
  };

  static readonly ChatRoomStatus = {
    open: "OPEN",
    closed: "CLOSED",
  };

  static readonly ChatRequestStatus = {
    pending: "PENDING",
    approved: "APPROVED",
    declined: "DECLINED",
  };

  static readonly EventResult = {
    success: "SUCCESS",
    failure: "FAILURE",
  };

  static readonly UserRole = {
    user: "user",
    psychic: "psychic",
  };

  static readonly UserTags = {
    Top: "top",
    Standard: "standard",
    Premium: "premium",
  };

  static readonly SOCKET_TIMEOUT = 10000;
  static readonly SOCKET_CONNECT_TIMEOUT = 1000;
  //static readonly SOCKET_URL = ":4000/";
  static readonly SOCKET_URL = "/";
}
