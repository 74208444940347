import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { constants } from "../../config/constants";
import SocketClient from "../../socketClient/socketClient";

export const joinChat = createAsyncThunk(
  "chat/join",
  (args: { chatSession: ChatSession; socketClient: SocketClient }) => {
    const { chatSession, socketClient } = args;
    return socketClient.emit(constants.ChatEvents.joinChat, chatSession);
  }
);

export const endChat = createAsyncThunk(
  "chat/end",
  (args: { chatSession: ChatSession; socketClient: SocketClient }) => {
    const { chatSession, socketClient } = args;
    return socketClient.emit(constants.ChatEvents.endChat, chatSession);
  }
);

export interface ChatMessageState {
  isJoining: boolean;
  joined: boolean;
}

const initialState: ChatMessageState = {
  isJoining: false,
  joined: false,
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setJoined: (state, action: PayloadAction<boolean>) => {
      state.joined = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(joinChat.pending, (state, action) => {
      state.isJoining = true;
    });
    builder.addCase(joinChat.rejected, (state, action) => {
      state.isJoining = false;
    });
    builder.addCase(joinChat.fulfilled, (state, action) => {
      state.isJoining = false;
      state.joined = true;
    });
    builder.addCase(endChat.rejected, (state, action) => {
      state.joined = false;
    });
    builder.addCase(endChat.fulfilled, (state, action) => {
      state.joined = false;
    });
  },
});

export const { setJoined } = messageSlice.actions;

export default messageSlice.reducer;
