import React, {useState} from "react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import "./applications.css";
import {useGetApplicationsQuery, useUpdateUserMutation} from "../../../store/reducers/adminApi";
import {Link} from "react-router-dom";

export function Applications() {
    const {isSuccess, isError, error, data: users, refetch} = useGetApplicationsQuery(null);
    const [showModal, setShowModal] = useState(false);
    const [modalUser, setModalUser] = useState(null);
    const [showNewApplications, setShowNewApplications] = useState(true);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = (user) => {
        setModalUser(user);
        setShowModal(true);
    };

    // // sort users by createdAy desc:
    // var usersToShow = users;
    // if (usersToShow && usersToShow.length > 0) {
    //     usersToShow.sort((a, b) => {
    //         return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    //     });
    // }

    return (
        <div>
            {/* Filter by approved/rejected applications:*/}
            {/* Show a label and radio buttons:*/}
            <div>
                Select applications type:
            </div>
            <label style={{marginRight: 15}}>
                <input
                    type="radio"
                    value="approved"
                    checked={showNewApplications}
                    onChange={() => setShowNewApplications(true)}
                />
                New applications
            </label>
            <label>
                <input
                    type="radio"
                    value="rejected"
                    checked={!showNewApplications}
                    onChange={() => setShowNewApplications(false)}
                />
                Rejected applications
            </label>
            <div>
                <small>
                    All approved psychics will be moved to a&nbsp;
                    <Link to={`/psychic/`}>Psychics Section</Link>
                </small>
            </div>


            <Table striped bordered hover className="table-hover">
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Created At</th>
                </tr>
                </thead>
                <tbody>
                {users &&
                    users.map((user) => {
                        const isNew = user.user_role === "psychic" && !user.approved;
                        const isRejected = user.user_role === "psychic" && user.application_rejected == true && user.approved === false;

                        if (showNewApplications && isNew || !showNewApplications && isRejected)
                            return (
                                <tr key={user.userID} onClick={() => handleShowModal(user)}>
                                    <td>{user.email}</td>
                                    <td>{user.name}</td>
                                    <td>{moment(user.created_at).format("MM-DD-YYYY")}</td>
                                </tr>
                            );
                    })}
                </tbody>
            </Table>
            {
                <ApplicationModal
                    user={modalUser}
                    show={showModal}
                    onHide={handleCloseModal}
                    onAction={() => refetch()}
                />
            }
        </div>
    );
}

export function ApplicationModal({user, show, onHide, onAction}) {
    const [updateUser, updateUserResult] = useUpdateUserMutation();

    const approve = () => {
        updateUser({userID: user.userID, approved: true})
            .unwrap()
            .catch((err) => {
                alert(err);
            })
            .finally(() => {
                onHide();
                onAction();
            });
    };

    const decline = () => {
        updateUser({userID: user.userID, approved: false, application_rejected: true, enabled: false})
            .unwrap()
            .catch((err) => {
                alert(err);
            })
            .finally(() => {
                onHide();
                onAction();
            });
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>User Application</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {user && (
                    <React.Fragment>
                        <Table>
                            <tbody>
                            <tr>
                                <td>Email</td>
                                <td>{user.email}</td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>{user.name}</td>
                            </tr>
                            <tr>
                                <td>Username</td>
                                <td>{user.username}</td>
                            </tr>
                            <tr>
                                <td>Created On</td>
                                <td>{moment(user.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                            </tr>
                            </tbody>
                        </Table>
                        <h3>Questionnaire</h3>
                        {user.registrationAnswers
                            ? (user.registrationAnswers as RegistrationAnswer[]).map((answer) => {
                                return (
                                    <div>
                                        <p className="bold">{answer.registrationQuestion.title}</p>
                                        <p>{answer.answer}</p>
                                    </div>
                                );
                            })
                            : null}
                    </React.Fragment>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={decline}>
                    Decline
                </Button>
                <Button variant="success" onClick={approve}>
                    Approve
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
