import React, { useState } from "react";
import "./sidebar.css";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import Nav from "react-bootstrap/Nav";
import { useGetSelfQuery } from "../../store/reducers/api";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { getAuth, signOut } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight, faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";

export function Sidebar() {
  const [collapsed, setCollapsed] = useState(true);

  const { isError, data: user } = useGetSelfQuery(null);

  const location = useLocation();

  const items = user ? sidebarItems(user.user_role) : [];

  const logout = () => {
    const auth = getAuth();
    signOut(auth);
  };

  const closeIcon = (
    <FontAwesomeIcon
      onClick={() => setCollapsed(!collapsed)}
      icon={faCircleChevronLeft}
      size="2x"
      className="close-icon"
    />
  );
  const openIcon = <FontAwesomeIcon icon={faCircleChevronRight} size="2x" />;

  return collapsed ? (
    <div className="sidebar-wrap-collapsed" onClick={() => setCollapsed(!collapsed)}>
      {openIcon}
    </div>
  ) : (
    <div className="sidebar-wrap">
      <a className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
        {closeIcon}
        <span className="fs-4">Relationship Psychic</span>
      </a>
      <hr />
      <Nav activeKey={location.pathname} className="nav nav-pills flex-column mb-auto">
        {items.map((item, i) => {
          return (
            <Nav.Item key={i}>
              <Nav.Link as={Link} to={item.link} eventKey={item.link}>
                {item.title}
              </Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>
      <div id="logout-wrapper">
        <Button onClick={logout}>Logout</Button>
      </div>
    </div>
  );
}

function sidebarItems(userRole: string) {
  switch (userRole) {
    case "admin":
      return [
        { title: "Analytics 🚀", link: "/analytics" },
        { title: "Applications", link: "/applications" },
        { title: "Reviews", link: "/reviews" },
        { title: "Chats", link: "/chats" },
        { title: "Psychics", link: "/psychics" },
        { title: "Users", link: "/users" },
        { title: "Transfers", link: "/transfers" },
        { title: "Block user", link: "/block-user" },
        { title: "Reports", link: "/reports" },
        { title: "Chat Value Codes", link: "/chat-value-codes" },
      ];
    case "psychic":
      return [{ title: "Account", link: "/account" }];
    case "user":
      return [
        { title: "Home", link: "/home" },
        { title: "Logout", link: "/logout" },
      ];
    default:
      return [];
  }
}
