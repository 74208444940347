import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface authState {
  signedIn: boolean;
}

const initialState: authState = {
  signedIn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userSignedIn: (state, action: PayloadAction) => {
      state.signedIn = true;
    },
    userSignedOut: (state, action: PayloadAction) => {
      state.signedIn = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userSignedIn, userSignedOut } = authSlice.actions;

export default authSlice.reducer;
