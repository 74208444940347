import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router";
import { constants } from "../../../config/constants";
import { SocketContext } from "../../../context/socketContext";
import { useAppDispatch, useAppSelector } from "../../../hooks/useAppSelector";
import { adminApi, useGetChatQuery } from "../../../store/reducers/adminApi";
import { endChat, joinChat } from "../../../store/reducers/chatMessageSlice";
import { transformServerMessage } from "../../../store/reducers/utils";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./chat.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

export function Chat() {
  let { chatID } = useParams();
  const dispatch = useAppDispatch();
  const socketClient = useContext(SocketContext);
  const { isSuccess, isError, error, data: chat, refetch } = useGetChatQuery(chatID);
  const joined = useAppSelector((state) => state.chatMessage.joined);
  const [chatTimeElapsed, setChatTimeElapsed] = useState(null);
  const [isEnding, setIsEnding] = useState(false);

  const activeChatSession =
    chat && chat.chatSessions
      ? chat.chatSessions.find(
          (chatSession) => chatSession.status === constants.ChatRoomStatus.open
        )
      : null;

  useEffect(() => {
    if (activeChatSession) {
      dispatch(
        joinChat({
          chatSession: { chatSessionID: activeChatSession.chatSessionID },
          socketClient,
        })
      );
    }

    // TODO: leave chat
  }, [activeChatSession, socketClient]);

  useEffect(() => {
    let onMessage, onEnd;
    if (joined && chat) {
      onMessage = (message) => {
        if (message.chatID === chat.chatID) {
          dispatch(
            adminApi.util.updateQueryData("getChat", chatID, (draftChat) => {
              draftChat.messages.unshift(
                transformServerMessage(message, chat.users) as ChatMessage
              );
            })
          );
        }
      };

      onEnd = (args) => {
        if (args.chatSession.chatID === chat.chatID) {
          refetch();
        }
      };

      socketClient.on(constants.ChatEvents.message, onMessage);
      socketClient.on(constants.ChatEvents.leftChatRoom, onEnd);
    } else {
      socketClient.off(constants.ChatEvents.message, onMessage);
      socketClient.off(constants.ChatEvents.leftChatRoom, onEnd);
    }

    return () => {
      socketClient.off(constants.ChatEvents.message, onMessage);
      socketClient.off(constants.ChatEvents.leftChatRoom, onEnd);
    };
  }, [joined, socketClient, chat]);

  useEffect(() => {
    let interval = null;
    if (activeChatSession) {
      interval = setInterval(() => {
        const pad = (n) => (n < 10 ? `0${n}` : n);
        const startTime = moment(activeChatSession.created_at);
        const diff = moment.duration(moment().diff(startTime));
        const h = Math.floor(diff.asHours());
        const m = Math.floor(diff.asMinutes() % 60);
        const s = Math.floor(diff.asSeconds() % 60);
        setChatTimeElapsed(`${pad(h)}:${pad(m)}:${pad(s)}`);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [chatTimeElapsed, activeChatSession]);

  const end = useCallback(() => {
    setIsEnding(true);
    dispatch(endChat({ chatSession: activeChatSession, socketClient }))
      .unwrap()
      .then(() => {
        setIsEnding(false);
        window.location.reload();
      });
  }, [activeChatSession]);

  return (
    <div>
      {activeChatSession && (
        <div id="live-indicators" className="d-flex flex-row">
          <Badge bg={joined ? "success" : "secondary"}>
            {joined ? "connected" : "connecting..."}
          </Badge>
          <div>{chatTimeElapsed}</div>
          <Button variant="danger" onClick={() => end()} disabled={isEnding}>
            End Chat
          </Button>
        </div>
      )}
      <Tabs defaultActiveKey="messages" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="messages" title="Messages">
          <Table responsive>
            <thead>
              <tr>
                <th>Message</th>
                <th>From</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {chat &&
                chat.messages.map((message) => {
                  const author = chat.users.find((user) => user.userID === message.user._id);

                  return (
                    <tr
                      key={message._id}
                      className={author.user_role === "psychic" ? "highlight" : ""}
                    >
                      <td>{message.text}</td>
                      <td>{author.name}</td>
                      <td>{moment(message.createdAt).format("MM/DD/YYYY HH:MM:SS")}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="sessions" title="Sessions">
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Created On</th>
                <th>Active Time</th>
                <th>Chat Rate</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {chat &&
                chat.chatSessions.map((chatSession) => {
                  return (
                    <tr key={chatSession.chatSessionID}>
                      <td>{chatSession.chatSessionID}</td>
                      <td>{moment(chatSession.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                      <td>
                        {chatSession.active_time ? chatSession.active_time + " seconds" : "-"}
                      </td>
                      <td>${(chatSession.chat_rate / 100).toFixed(2)}</td>
                      <td>{chatSession.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="info" title="Info">
          <Table>
            {chat && (
              <tbody>
                <tr>
                  <td>Created On</td>
                  <td>{moment(chat.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                </tr>
                <tr>
                  <td>Users</td>
                  <td>
                    {chat.users.map((user) => {
                      return (
                        <Link className="user-link" to={`/${user.user_role}/${user.userID}`}>
                          {user.username}
                        </Link>
                      );
                    })}
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </Tab>
      </Tabs>
    </div>
  );
}
