import { Socket } from "socket.io-client";
import io from "socket.io-client";
import { constants } from "../config/constants";

export default class SocketClient {
  socket: Socket;
  url: string;
  user: any;
  loadFn: any;

  async connect(url = null, user = null, onConnect = null, onDisconnect = null) {
    if (url) this.url = url;
    if (user) this.user = user;

    if (!this.socket || !this.socket.connected) {
      this.socket = io(url, {
        autoConnect: false,
        forceNew: false,
        timeout: constants.SOCKET_CONNECT_TIMEOUT,
        transports: ["poll"],
        auth: async (cb) => {
          cb({
            token: await this.user.getIdToken(),
          });
        },
      });
    }

    return new Promise<void>(async (resolve, reject) => {
      this.socket.on("connect", () => {
        onConnect();
        resolve();
      });
      this.socket.on("connect_error", async (error) => {
        reject(error);
      });
      this.socket.on("disconnect", () => {
        onDisconnect();
        console.log("disconnected");
      });
      this.socket.connect();
    });
  }

  disconnect() {
    return new Promise<void>((resolve) => {
      this.socket.off();
      this.socket.disconnect();
      this.socket = null;
      resolve();
    });
  }

  emit(event, data) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");

      const emitFn = () => {
        this.socket.timeout(constants.SOCKET_TIMEOUT).emit(event, data, (err, response) => {
          if (response && response.error) {
            console.log(response.error);
            response.error.code = response.error.code.toString();
            return reject(response.error);
          }

          if (err) {
            return reject(err);
          }

          return resolve(response);
        });
      };

      if (!this.socket.connected) {
        console.log("reconnecting");
        this.socket.once("connect", () => {
          emitFn();
        });

        this.socket.connect();
      } else {
        emitFn();
      }
    });
  }

  on(event, fun) {
    return new Promise<void>((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");

      this.socket.on(event, fun);
      resolve();
    });
  }

  off(event, fun) {
    this.socket.off(event, fun);
  }
}
