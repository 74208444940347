// import React, {useState} from "react";
// import Table from "react-bootstrap/Table";
// import moment from "moment";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// import {useGetUserQuery, useGetUsersQuery} from "../../../store/reducers/adminApi";
// import Image from "react-bootstrap/Image";
// import Badge from "react-bootstrap/Badge";
// import {useNavigate} from "react-router";
//
// export function Users() {
//     const [page, setPage] = useState(1)
//     const [searchQuery, setSearchQuery] = useState<string | null>(null)
//     // const [email, setEmail] = useState<string|null>(null)
//     // const [name, setName] = useState<string|null>(null)
//
//     const {isSuccess, isError, isFetching, error, data: users, refetch} = useGetUsersQuery({
//         page: page,
//         searchQuery: searchQuery,
//         name: null,
//         email: null
//     })
//     console.log('Re-render users', isSuccess, isError, isFetching, error, users);
//
//     const navigate = useNavigate();
//
//     const updateSearchQuery = () => {
//         const searchInput = document.getElementById("search-input") as HTMLInputElement;
//         const searchQuery = searchInput.value;
//         if (searchQuery === "" || searchQuery === null) {
//             setSearchQuery(null);
//             setPage(1)
//             return;
//         }
//         if (searchQuery.length < 3) {
//             alert("Search query must be at least 5 characters long. Otherwise - server uses too much memory while going all over the users in the DB and might crash.");
//             return;
//         }
//         console.log('Upd search query 1')
//         setSearchQuery(searchQuery);
//         console.log('Upd search query 2')
//         setPage(1);
//         console.log('Upd search query 3')
//         refetch();
//         console.log('Upd search query 4')
//     }
//
//     // const onEmailChange = (newEmail) => {
//     //     setEmail(newEmail);
//     // }
//     // const onNameChange = (newName) => {
//     //     setName(newName);
//     // }
//
//     return (
//         <div>
//             Page # {page}
//             {isFetching ? "Loading..." : null}
//             <div>
//                 <label htmlFor="search">Search:
//                 </label>
//                 <div>
//                     <input type="search" id={"search-input"} placeholder={"Search by email or name"}/>
//                     <a href={"#"} style={{marginLeft: 5}} onClick={() => {
//                         updateSearchQuery()
//                     }}>Search</a>
//                 </div>
//             </div>
//             <Table striped bordered hover className="table-hover">
//                 <thead>
//                 <tr>
//                     <th>
//                         <div>Email</div>
//                         {/*<div>*/}
//                         {/*    <input type="search" placeholder={"Search by email"}*/}
//                         {/*    value={email}*/}
//                         {/*    onChange={e => onEmailChange(e.target.value)}/>*/}
//                         {/*    {email}*/}
//                         {/*</div>*/}
//                     </th>
//                     <th>
//                         <div>Name</div>
//                         {/*<div>*/}
//                         {/*    <input type="search" placeholder={"Search by name"}*/}
//                         {/*    value={name}*/}
//                         {/*    onChange={e => onNameChange(e.target.value)}/>*/}
//                         {/*</div>*/}
//                     </th>
//
//                     <th>Created At</th>
//                     <th>Total Spend, $</th>
//                     <th>Chats #</th>
//                     <th>Chat Requests #</th>
//                 </tr>
//                 </thead>
//                 <tbody>
//                 {users &&
//                     users.map((user) => {
//                         if (user.user_role === "user")
//                             console.log(user);
//                         let totalSpend = 0;
//                         if (user.payments) {
//                             user.payments.forEach((payment) => {
//                                 totalSpend += payment.charge_amount;
//                             });
//                         }
//                         const uniqueChats = new Set((user.chatParticipants ?? []).map((e) => e.chat_id))
//
//                         return (
//                             <tr key={user.userID} onClick={() => navigate(`/user/${user.userID}`)}>
//                                 <td>{user.email}</td>
//                                 <td>{user.name}</td>
//                                 <td>{moment(user.created_at).format("MM-DD-YYYY")}</td>
//                                 <td style={{color: totalSpend == 0 ? "#c4c4c4" : ""}}>${(totalSpend / 100).toFixed(2)}</td>
//                                 <td style={{color: uniqueChats.size == 0 ? "#c4c4c4" : ""}}>{uniqueChats.size}</td>
//                                 <td style={{color: user.chatRequestsSent == null || user.chatRequestsSent.length == 0 ? "#c4c4c4" : ""}}>{(user.chatRequestsSent ?? []).length}</td>
//                             </tr>
//                         );
//                     })}
//                 </tbody>
//             </Table>
//             Current Page: {page}
//             {page > 1 ? <button style={{marginRight: '3px', marginLeft: '3px'}} onClick={() => setPage(page - 1)}>
//                 Previous
//             </button> : <></>}
//
//             {users != null && users.length > 0 ?
//                 <button style={{marginRight: '3px', marginLeft: '3px'}} onClick={() => setPage(page + 1)}>
//                     Next
//                 </button> : <></>}
//         </div>
//     );
// }


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Table from "react-bootstrap/Table";
import moment from "moment";

import { useGetUsersQuery } from "../../../store/reducers/adminApi";

export function Users() {
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const navigate = useNavigate();

    // Debounce search query to avoid too many API calls
    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchQuery === "" || searchQuery === null) {
                setDebouncedSearch("");
                return;
            }
            if (searchQuery.length >= 3) {
                setDebouncedSearch(searchQuery);
                setPage(1); // Reset to first page when search changes
            }
        }, 500); // Wait 500ms after last keystroke before searching

        return () => clearTimeout(timer);
    }, [searchQuery]);

    const { data: users, isFetching } = useGetUsersQuery({
        page,
        searchQuery: debouncedSearch || null,
        name: null,
        email: null
    });

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length === 0) {
            setSearchQuery("");
            return;
        }
        if (value.length < 3) {
            setSearchQuery(value);
            return;
        }
        setSearchQuery(value);
    };

    const renderUserRow = (user: any) => {
        const totalSpend = (user.payments || []).reduce(
            (sum: number, payment: any) => sum + payment.charge_amount,
            0
        );
        const uniqueChats = new Set((user.chatParticipants ?? []).map((e: any) => e.chat_id));

        return (
            <tr key={user.userID} onClick={() => navigate(`/user/${user.userID}`)}>
                <td>{user.email}</td>
                <td>{user.name}</td>
                <td>{moment(user.created_at).format("MM-DD-YYYY")}</td>
                <td style={{ color: totalSpend === 0 ? "#c4c4c4" : "" }}>
                    ${(totalSpend / 100).toFixed(2)}
                </td>
                <td style={{ color: uniqueChats.size === 0 ? "#c4c4c4" : "" }}>
                    {uniqueChats.size}
                </td>
                <td style={{ color: !user.chatRequestsSent?.length ? "#c4c4c4" : "" }}>
                    {user.chatRequestsSent?.length || 0}
                </td>
            </tr>
        );
    };

    return (
        <div>
            <div>Page # {page}</div>

            <div className="mb-3">
                <label htmlFor="search-input" className="form-label">
                    Search:
                </label>
                <div className="d-flex align-items-center">
                    <input
                        type="search"
                        id="search-input"
                        className="form-control"
                        placeholder="Search by email or name (min 3 characters)"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                    />
                    {searchQuery.length > 0 && searchQuery.length < 3 && (
                        <div className="text-danger ms-2">
                            Enter at least 3 characters to search
                        </div>
                    )}
                </div>
            </div>

            {isFetching && <div className="my-3">Loading...</div>}

            <Table striped bordered hover className="table-hover">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Created At</th>
                        <th>Total Spend, $</th>
                        <th>Chats #</th>
                        <th>Chat Requests #</th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map(renderUserRow)}
                </tbody>
            </Table>

            <div className="d-flex align-items-center gap-2">
                <span>Current Page: {page}</span>
                {page > 1 && (
                    <button
                        className="btn btn-secondary"
                        onClick={() => setPage(page - 1)}
                    >
                        Previous
                    </button>
                )}
                {users?.length > 0 && (
                    <button
                        className="btn btn-secondary"
                        onClick={() => setPage(page + 1)}
                    >
                        Next
                    </button>
                )}
            </div>
        </div>
    );
};