import React from "react";
import Button from "react-bootstrap/Button";
import { getAuth } from "firebase/auth";

export function Account() {
  const setupRedirect = async () => {
    const auth = getAuth();
    const token = auth.currentUser ? await auth.currentUser.getIdToken(true) : null;
    window.location.replace(`/api/payment/setup-redirect?token=${token}`);
  };

  return (
    <div>
      <Button onClick={setupRedirect}>Setup Account</Button>
    </div>
  );
}
