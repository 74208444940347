import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    { provider: firebase.auth.EmailAuthProvider.PROVIDER_ID, disableSignUp: { status: true } },
  ],
  callbacks: {
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      return false;
    },
  },
};

export function LoginPage() {
  return (
    <div className="login-wrapper">
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  );
}
