import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Spinner, Table } from 'react-bootstrap'
import ToastBar, { ToastData } from '../../../components/ToastBar/toastbar'
import { usePostBlockUserMutation, useGetBlockedUsersQuery, usePatchBlockUserMutation } from '../../../store/reducers/adminApi'

const BlockUser = () => {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState<ToastData>({
        title: null,
        body: null,
        type: null
    })

    const [postBlockUser] = usePostBlockUserMutation();
    const [toggleUser] = usePatchBlockUserMutation();

    const { data = [], error, isLoading, refetch } = useGetBlockedUsersQuery(null);

    const handleOnChange = (e: any) => {
        setEmail(e.target.value)
    }

    const handleBlockUser = async () => {
        try {
            setLoading(true);
            await postBlockUser({ email }).unwrap();
            refetch();
            setEmail('');
            setToastMessage({
                title: "Success",
                body: "User blocked successfully",
                type: "success"
            })
            setLoading(false)
        } catch (error: any) {
            console.log(error);
            setLoading(false)
            setToastMessage({
                title: "Error",
                body: error.message || "Something went wrong",
                type: "danger"
            })
        }
    }

    const handleToggleUser = async (id: string) => {
        try {
            await toggleUser({ id });
            refetch();
        } catch (error: any) {
            console.log(error)
            setToastMessage({
                title: "Error",
                body: error.message || "Something went wrong",
                type: "danger"
            })
        }
    }

    useEffect(() => {
        setToastMessage((value) => {
            if (error) {
                return {
                    title: "Error",
                    body: "Something went wrong",
                    type: "danger"
                }
            }
            return value;
        })

    }, [error])

    return (
        <div className='container'>
            <ToastBar tostData={toastMessage} setToastData={setToastMessage} />
            <div className='mt-5'>
                <div className="input-group mb-3">
                    <input
                        disabled={loading}
                        type="email"
                        className="form-control"
                        placeholder="Enter email of user"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={email}
                        onChange={handleOnChange}
                    />
                    <Button disabled={loading} className="btn input-group-append" onClick={handleBlockUser}>
                        Block
                    </Button>
                </div>

            </div>
            {isLoading ? (
                <div className='container d-flex mt-5'>
                    <Spinner className="mx-auto" animation="border" variant="primary" />
                </div>
            ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Created at</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.email}</td>
                                <td> {item.status ? "Disabled" : "Enabled"}</td>
                                <td>{moment(item.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                                <td>
                                    <Button variant={item.status ? "success" : "danger"} onClick={() => handleToggleUser(item.id)}>
                                        {item.status ? "Enable" : "Disable"}
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <div></div>
            <div></div>
        </div>
    )
}

export default BlockUser