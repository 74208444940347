import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useGetPsychicsQuery, useGetUserQuery, useGetUsersQuery} from "../../../store/reducers/adminApi";
import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";
import { useNavigate } from "react-router";

export function Psychics() {
  const [page, setPage] = useState(1)
  const { isSuccess, isError, error, data: users, refetch } = useGetPsychicsQuery(page);

  const navigate = useNavigate();

  return (
    <div>
      <Table striped bordered hover className="table-hover">
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((user) => {
              if (user.user_role === "psychic")
                return (
                  <tr key={user.userID} onClick={() => navigate(`/psychic/${user.userID}`)}>
                    <td>{user.email}</td>
                    <td>{user.name}</td>
                    <td>{moment(user.created_at).format("MM-DD-YYYY")}</td>
                  </tr>
                );
            })}
        </tbody>
      </Table>
        Current Page: {page}
        {page > 1 ? <button style={{marginRight: '3px', marginLeft: '3px'}} onClick={() => setPage(page - 1)}>
          Previous
        </button> : <></>}

        {users != null && users.length > 0 ? <button style={{marginRight: '3px', marginLeft: '3px'}} onClick={() => setPage(page + 1)}>
          Next
        </button> : <></>}
    </div>
  );
}
