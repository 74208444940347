import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  useUpdateReviewMutation,
  useGetReviewsQuery, useRemoveReviewMutation,
} from "../../../store/reducers/adminApi";
import {Link} from "react-router-dom";

export function Reviews() {
  const { isSuccess, isError, error, data: reviews, refetch } = useGetReviewsQuery(null);
  const [updateReview, updateReviewResult] = useUpdateReviewMutation();
  const [removeReview, removeReviewResult] = useRemoveReviewMutation();
  const [loading, setLoading] = useState(false);

  const setApproved = (reviewID, approved) => {
    setLoading(true);
    updateReview({ approved, reviewID })
      .unwrap()
      .then(() => {
        refetch();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRemoveReview = (reviewID) => {
    const yes = window.confirm("Are you sure you want to delete this review?");
    if (!yes) return;
    setLoading(true);
    removeReview({ reviewID })
        .unwrap()
        .then(() => {
            refetch();
        })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Reviewer</th>
            <th>Psychic</th>
            <th>Rating</th>
            <th>Text</th>
            <th>Written On</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reviews &&
            reviews.map((review) => {
              return (
                <tr key={review.reviewID}>
                  <td>{review.reviewer.username}</td>
                  <td>{review.reviewee.username}</td>
                  <td>{review.rating} / 5</td>
                  <td>{review.text}</td>
                  <td>{moment(review.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                  <td>
                    <div className="flex-row" style={{textAlign: 'center'}}>
                      {review.approved ? (
                        <Button
                          disabled={loading}
                          variant="danger"
                          onClick={() => {
                            setApproved(review.reviewID, false);
                          }}
                        >
                          Hide
                        </Button>
                      ) : (
                        <Button
                          disabled={loading}
                          variant="success"
                          onClick={() => {
                            setApproved(review.reviewID, true);
                          }}
                        >
                          Approve
                        </Button>
                      )}

                      <div style={{textAlign: 'center'}}>
                        <a
                            style={{fontSize: 10, textAlign: 'center'}}
                            href={"#"}
                            onClick={() => {
                              onRemoveReview(review.reviewID);
                            }}
                          >
                            Remove
                          </a>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}
