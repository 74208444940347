export const transformServerMessage = (
  arg: any | any[],
  users: User[]
): ChatMessage | ChatMessage[] => {
  const transform = (message) => {
    const user = users.find((user) => user.userID == message.from);

    return {
      _id: message.chatMessageID,
      chatID: message.chatID,
      chatSessionID: message.chatSessionID,
      text: message.content,
      createdAt: message.created_at,
      user: {
        _id: message.from,
        name: user.name,
        avatar: user.avatar,
      },
    };
  };

  if (Array.isArray(arg)) {
    return arg.map((message) => {
      return transform(message);
    });
  } else {
    return transform(arg);
  }
};
