import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useGetReportsQuery, useUpdateReportMutation } from "../../../store/reducers/adminApi";

export function Reports() {
  const { data: reports, refetch } = useGetReportsQuery(null);
  const [updateReport, updateReportResult] = useUpdateReportMutation();
  const [loading, setLoading] = useState(false);

  const setReviewed = (id, reviewed) => {
    setLoading(true);
    updateReport({ reviewed, id })
      .unwrap()
      .then(() => {
        refetch();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Reporting User</th>
            <th>Reported User</th>
            <th>Text</th>
            <th>Written On</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reports &&
            reports.map((report) => {
              return (
                <tr key={report.id}>
                  <td>{report?.reportingUser?.username}</td>
                  <td>{report?.reportedUser?.username}</td>
                  <td>{report?.text}</td>
                  <td>{moment(report.created_at).format("MM-DD-YYYY HH:MM:SS")}</td>
                  <td>
                    <div className="flex-row">
                      {report.reviewed ? (
                        <Button
                          disabled={loading}
                          variant="danger"
                          onClick={() => {
                            setReviewed(report.id, false);
                          }}
                        >
                          Mark As Needs Review
                        </Button>
                      ) : (
                        <Button
                          disabled={loading}
                          variant="success"
                          onClick={() => {
                            setReviewed(report.id, true);
                          }}
                        >
                          Mark As Reviewed
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}
