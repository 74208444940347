export const permissions = {
  admin: [
    "applications",
    "reviews",
    "chats",
    "chat",
    "psychics",
    "users",
    "payments",
    "payouts",
    "stripe-link",
    "analytics",
  ],
  user: [],
  psychic: ["stripe-link", "account-details"],
};
